import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestTransformer } from 'axios'
import config from 'config';
import { CustomEventSource } from 'extended-eventsource'
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { RequestConfig } from './apiCore';
import { useMemo } from 'react';
import { store } from 'redux/store';
import { setAppError, setIsActive, setLoading } from 'redux/actions';
import { Mutex } from 'async-mutex';
import { stubTrue } from 'lodash';

class APICoreNewClient {
    private eventSource: CustomEventSource | null = null;
    private auth: AuthContextProps;
    private axiosInstance: AxiosInstance
    private static redirectMutex: Mutex = new Mutex()

    private token: string | undefined

    constructor(auth: AuthContextProps) {

        this.auth = auth;
        this.token = this.auth.user?.access_token;

        this.axiosInstance = axios.create({
            baseURL: config.API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
            // timeout: 10000,
        })

        this.axiosInstance.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                (config as RequestConfig).showLoading && store.dispatch(setLoading(true));

                if (this.token) {
                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${this.token}`,
                    };
                }
                return config;
            },
            (error) => Promise.reject(error)
        )

        this.axiosInstance.interceptors.response.use(
            (response) => {
                (response.config as RequestConfig).showLoading && store.dispatch(setLoading(false));
                store.dispatch(setIsActive(true));
                return response;
            },
            async (error) => {
                (error.config as RequestConfig).showLoading && store.dispatch(setLoading(false));

                // Any status codes that falls outside the range of 2xx cause this function to trigger
                let message;

                // const isActive = store.getState().Auth.isActive;
                let asWarning = false;
                if (error && error.response) {
                    switch (error.response.status) {
                        case 401: {
                            if (error.response._retry) {
                                window.location.href = '/account/login';
                                return Promise.reject(error);
                            }

                            error.config._retry = true

                            try {

                                const token = error.config.headers?.Authorization?.split(' ')[1]

                                const isContinue = await APICoreNewClient.redirectMutex.runExclusive(async () => {

                                    if (token != this.token)
                                        return true
                                        
                                    try {
                                        let user = await this.auth.signinSilent();
                                        this.token = user?.access_token
                                        return user != null
                                    }
                                    catch {
                                        return false
                                    }

                                })

                                if (!isContinue) {
                                    message = 'Invalid credentials';
                                    window.location.href = '/account/login';
    
                                    return Promise.reject(error);

                                }

                                if (this.token) {

                                    error.config.headers = {
                                        ...error.config.headers,
                                        Authorization: `Bearer ${this.token}`,
                                    };

                                    return this.axiosInstance(error.config)
                                }
                            }
                            catch (error) {
                                message = 'Invalid credentials';
                                window.location.href = '/account/login';

                                return Promise.reject(error);
                            }
                        }
                            break;
                        case 403:
                            // window.location.href = '/access-denied';
                            message = 'Access Forbidden';
                            break;
                        case 404:
                            asWarning = true;
                            message = 'Sorry! The data you are looking for could not be found';
                            break;
                        default: {
                            let errData = error.response && error.response.data;

                            if (errData?.message) {
                                message = errData.message;
                            } else if (errData?.detail) {
                                message = errData.detail;
                            } else {
                                message = errData;
                            }
                        }
                    }

                    if (error.config.url?.includes('/api/v1/addresses/') && error?.response?.status === 400) {
                        const unconfirmedData = error?.response?.data?.unconfirmedComponents;
                        const missingData = error?.response?.data?.missingComponents;
                        if (missingData?.length > 0 && Array.isArray(missingData)) {
                            const missingDataString = missingData.join(',');
                            message = `The address you provided is missing ${missingDataString} . Please double-check the address and try again!`;
                        } else if (unconfirmedData?.length > 0 && Array.isArray(unconfirmedData)) {
                            const unconfirmedDataString = unconfirmedData.join(', ');
                            message = `The ${unconfirmedDataString} you provided is unconfirmed. Please double-check the address and try again!`;
                        } else {
                            message =
                                'The address you provided is not recognized. Please double-check the address and try again!';
                        }
                    }
                    console.log({ persistError: !!(error.config as RequestConfig)?.persistError, c: error.config });
                    const title = error.response.data?.title ? error.response.data.title : 'Error';
                    const errors = error.response.data?.errors?.length > 0 ? error.response.data.errors : [message];

                    if (!(error.config as RequestConfig)?.hideError) {
                        if (!(error.config as RequestConfig)?.hide404) {
                            store.dispatch(
                                setAppError({
                                    title,
                                    errors,
                                    warning: asWarning,
                                    persistError: !!(error.config as RequestConfig)?.persistError,
                                })
                            );
                        }
                    }

                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        )
    }

    createURL = (url: string, params: { [key: string]: any }) => {
        const request = Object.entries(params)
            .reduce(
                (acc, entry) => (!entry || !entry[0] || !entry[1] ? acc : [...acc, `${entry[0]}=${entry[1]}`]),
                [] as string[]
            )
            .join('&');
        return `${this.axiosInstance.defaults.baseURL}${url}?${request}`;
    };

    /**
     * Fetches data from given url
     */
    get = <ReturnType>(url: string, params?: RequestConfig) => {
        return this.axiosInstance.get<ReturnType>(url, params).then((response) => {
            return response.data;
        });
    };

    /**
     * Async fetches data from given url
     */
    getAsync = async <ReturnType>(url: string, params?: RequestConfig) => {
        const response = await this.axiosInstance.get<ReturnType>(url, params);
        return response.data;
    };

    getEventSource = (
        url: any,
        onMessage: (event: any) => void,
        onError: (error: any) => void,
        params?: RequestConfig
    ) => {

        const authorizationHeader = this.auth.user?.access_token
        let authHeaderString: string = '';

        if (authorizationHeader != null) {
            authHeaderString = authorizationHeader + '';
        }

        if (this.eventSource != null) {
            this.eventSource.close();
            this.eventSource = null;
        }

        this.eventSource = new CustomEventSource(url, {
            headers: {
                Authorization: `Bearer ${authHeaderString}`,
            },
            retry: 3000,
        });

        this.eventSource.onopen = () => {
            console.log('Connection opened');
        };

        this.eventSource.onmessage = (event: MessageEvent) => {
            onMessage(event);
        };

        this.eventSource.onerror = (error) => {
            console.error('Error occurred:', error);
            onError(error);
        };

        return () => {
            if (this.eventSource !== null) this.eventSource.close();
        };
    };

    getParams = <ReturnType>(url: string, params: any) => {

        console.log('dasdaddsaasdasdasdsa')
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = this.axiosInstance.get<ReturnType>(`${url}?${queryString}`, params);
        } else {
            response = this.axiosInstance.get<ReturnType>(`${url}`, params);
        }
        return response.then((response) => {
            return response.data;
        });
    };

    getFile = <ReturnType>(url: string, params?: any, config?: AxiosRequestConfig) => {
        let response;

        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .filter(key => params[key]).map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = this.axiosInstance.get<ReturnType>(`${url}?${queryString}`, {
                responseType: 'blob',
            });
        } else {
            response = this.axiosInstance.get<ReturnType>(`${url}`, {
                responseType: 'blob',
            });
        }
        return response.then((response) => {
            return response;
        });
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(this.axiosInstance.get(`${url}?${queryString}`));
        }

        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    post = <ReturnType>(url: string, data: any, params?: RequestConfig) => {

        return this.axiosInstance.post<ReturnType>(url, data, params).then((response) => {
            return response.data;
        });
    };

    /**
     * put given data to url
     */
    put = <ReturnType>(url: string, data: any, params?: RequestConfig) => {

        return this.axiosInstance.put<ReturnType>(url, data, params).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url
     */
    create = <ReturnType>(url: string, data: any) => {
        return this.axiosInstance.post<ReturnType>(url, data).then((response) => {
            return response.data;
        });
    };

    /**
     * Updates patch data
     */
    updatePatch = <ReturnType>(url: string, data?: any, params?: RequestConfig) => {

        return this.axiosInstance.patch<ReturnType>(url, data, params).then((response) => {
            return response.data;
        });
    };

    /**
     * Updates data
     */
    update = <ReturnType>(url: string, data: any, params?: RequestConfig) => {

        return this.axiosInstance.put<ReturnType>(url, data, params).then((response) => {
            return response.data;
        });
    };

    /**
     * Deletes data
     */
    delete = <ReturnType>(url: string, params?: RequestConfig) => {

        return this.axiosInstance.delete<ReturnType>(url, params).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url with file
     */
    createWithFile = <ReturnType>(url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const axiosConfig: any = {
            headers: {
                ...this.axiosInstance.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return this.axiosInstance.post<ReturnType>(url, formData, axiosConfig).then((response) => {
            return response.data;
        });
    };

    /**
     * post given data to url with file
     */
    updateWithFile = <ReturnType>(url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const axiosConfig: any = {
            headers: {
                ...this.axiosInstance.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return this.axiosInstance.patch<ReturnType>(url, formData, axiosConfig).then((response) => {
            return response.data;
        });
    };

}

export default APICoreNewClient;

export const useApiClient = (): APICoreNewClient => {
    const auth = useAuth()
    const client = useMemo(() => new APICoreNewClient(auth), [auth])
    // const client = new APICoreNewClient(auth)

    return client;
};